import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { FaArrowRight } from 'react-icons/fa';

import Joshual from '../../images/joshual.png'

import FeatureIcon1 from '../../images/features/spin-the-wheel-popups/form-editor.png'
import FeatureIcon2 from '../../images/features/spin-the-wheel-popups/set-probability.png'
import FeatureIcon3 from '../../images/features/spin-the-wheel-popups/edit-message.png'

class HomePopupTriggerRules extends Component {
    render() {
        return (
            <div className="email-popup-trigger-rules-section py-5">
                <div className="container">
                    <div className="hero-content wow fadeIn text-center mb-5">
                        <h2>Why Optinly’s Gamification <span className="style-highlight"> Spin the Wheel Popup
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span> Stands Out? </h2>
                        <p>Display email collection popups to web visitors when it matters the most. Triggering email popups at the right time constitutes to half the conversions. Grab attention and capture leads as you like with Optinly’s gamification popups.</p>
                    </div>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4 pe-5">
                                <h3>Spin Wheel Customization At Its Best</h3>
                                <p>Let spin the wheel popup speak for your brand. Customize popup templates to a high extent using the built-in popup editor – change background colors, peg color, add custom <strong>“win/lose messages”</strong> and do more with Optinly.</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon1} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon2} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5">
                                <h3>Add Coupons, GDPR Checkbox, Set Probability And More</h3>
                                <p>Make your spin wheel popups engaging and interactive. Add multiple coupons and segments to your spin the wheel. It could be a 10% coupon or a 20% coupon – you can add it easily by customizing specific segments of the wheel. Also, set probability for each segment and decide what your web audience should get out of the spin!</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center pt-5">
                        <Col md="6">
                            <div className="mb-4 pe-5">
                                <h3>Custom Win/Lose Info Messages</h3>
                                <p>Create and display custom win/lose messages after your visitors spin the wheel. Allow web audience to copy coupons to the clipboard for future use. Also, decide whether or not to redirect users after copying the coupon. Add custom URLs to redirect visitors.</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon3} alt="" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default HomePopupTriggerRules
