import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { FaArrowRight } from 'react-icons/fa';

import FeatureIcon1 from '../../images/features/spin-the-wheel-popups/spin-the-wheel-campaign.png'
import FeatureIcon2 from '../../images/features/spin-the-wheel-popups/Choose-spin-the-wheel-template.png'
import FeatureIcon3 from '../../images/features/spin-the-wheel-popups/popup-editor.png'
import FeatureIcon4 from '../../images/features/spin-the-wheel-popups/rules-1.png'

export class HomePopupFeatures extends Component {
    render() {
        return (
            <div className="home-popup-features-section">
                <div className="container">
                    <Row className="align-items-center text-center py-3">
                        <Col md="12">
                            <div className="hero-content wow fadeIn">
                                <h2>Build Your First Spin to Win Popup Campaign in <span className="style-highlight"> 4 Simple Steps 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon1} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5 pe-3">
                                <h3>Create New Campaign</h3>
                                <p>Sign up for Optinly and you’ll be taken to the Welcome Dashboard after setup. Create your first popup campaign – choose a growth goal and set a custom name.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4 ps-3 pe-5">
                                <h3>Choose Subscription Popup Template</h3>
                                <p>Optinly comes with 100+ ready-to-use, highly customizable and responsive email collection templates. Choose an ideal template for your campaign.</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon2} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon3} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5 pe-3">
                                <h3>Customize Email Popup Template</h3>
                                <p>Tweak popup templates to suit your ideas and brand’s theme. Use Optinly’s built-in popup editor to customize templates – get what you see on your screen.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center pt-5">
                        <Col md="6">
                            <div className="mb-4 ps-3 pe-5">
                                <h3>Set Triggering Rules and Go Live</h3>
                                <p>Decide when your email subscription popup should be displayed to your visitor. Now you’re all set to make your first email popup campaign live.</p>
                                <p>
                                    <a className="btn btn-secondary mt-3" href="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" target="_blank">
                                        Create Email Popup <FaArrowRight />
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon4} alt="" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default HomePopupFeatures
