import React from 'react'
import Layout from '../../components/layout'
import Metatags from "../../components/Metatags"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
//import home page components
import GetStarted from '../../components/getstarted'
import Banner from '../../components/Banner';
import HomeIntegrationCompanies from '../../constants/Home/IntegrationCompanies';
import HomeCounter from '../../constants/Home/homeCounter';
import HomePopupFeatures from '../../constants/SpinWheelPopups/HomePopupFeatures';
import HomePopupTriggerRules from '../../constants/SpinWheelPopups/HomePopupTriggerRules';
import HomePopupClients from '../../constants/EmailPopups/HomePopupClients';
import PopupEmailEditor from '../../constants/SpinWheelPopups/PopupEmailEditor';
import SwiperSlider from '../../components/SwiperSlider';
import Bg from '../../images/background-1.png';
import SliderImg1 from '../../images/mike.png';
import SliderImg2 from '../../images/jockbrocas.png';
import SliderImg3 from '../../images/caseyjones.png';

const Title = () => (
    <><h1><span className="style-highlight">Spin the Wheel Pop-ups.
        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
    </span> Promote Engagement and Increase Conversions & Sales.</h1></>
);

const Slides = [
    {
        content: `
            <div className="swiper-slide-contents">
                <div className="elementor-slide-heading">"Was Not a fan of Popups, Until Now!"</div>
                <div className="elementor-slide-description">What I like here is, there is no subscriber limit and their templates are amazing. It also has a dashboard to monitor conversions/campaigns for all my website/clients!<br/><br/>
                <b>Mike</b> - mrdzyn.com 
                </div>
            </div>`,
        img: SliderImg1
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">"I Ditched Optinmonster&#8203;"</div><div className="elementor-slide-description">This has been the answer to my needs. I have jumped all in and now that I have played with it, I am slowly moving all my sites over to Optinly.<br><br><b>JockBrocas</b> - jockbrocas.com</div>
            </div>`,
        img: SliderImg2
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">Love Optinly!</div><div className="elementor-slide-description">One word pretty well sums it up - Awesome!<br><br>
            <b>Casey Jones</b> - www.cjco.com.au</div></div>`,
        img: SliderImg3
    },
]

const SwiperSlides = () => (
    <div className="position-relative">
        <SwiperSlider slidesPerView={1} slides={Slides} spaceBetween={0} slideDeley={5000} />
    </div>
);

const SpinTheWheelPopups = () => (
    <Layout className="landing-page">
        <Metatags
            title="Spin the Wheel Pop-ups"
            description="Use Optinly’s spin-the-wheel popup to increase sales, capture leads, and drive conversion on your website."
            keywords={[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url="https://www.optinly.com"
            pathname="/"
        />
        <Banner title={<Title />} description="Capture leads and increase sales the new way. Use the best discount wheel popup plugin to 10x your signups and sales." actionUrl="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" actionText={"Create Spin the Wheel Popups"} slider={<SwiperSlides />} bgImg={Bg} />

        <HomeIntegrationCompanies />
        <HomeCounter />
        <HomePopupFeatures />
        {/* <HomePopupTypes /> */}
        <HomePopupTriggerRules />
        <PopupEmailEditor />
        <div className="py-5 faq-section">
            <div className="container py-3">
                <div className="mb-5 text-center hero-content wow fadeIn">
                    <h2>Spin the Wheel Popup <span className="style-highlight"> FAQs
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span>
                    </h2>
                </div>
                <Accordion allowZeroExpanded>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Is spin the wheel marketing an effective strategy?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Gamification has always been an effective strategy to drive more conversions and sales on your website. Stats say that more than 50% of the eCommerce stores use spin the wheel marketing and witness significant conversion rates. Here are a few reasons <a href="https://optinly.com/blog/reasons-to-use-spin-the-wheel-app/">why you should use spin the wheel marketing for your website</a>.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                How many spin to win popup campaigns can I create with Optinly?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Optinly allows you to create an unlimited number of spin the wheel campaigns with no limit on the number of pageviews/sessions.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                How do I create a spin to win popup campaign?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>With Optinly, you can create your first spin the wheel marketing campaign in under a couple of minutes. You can either follow the above-mentioned steps or <a href="https://optinly.com/blog/how-to-make-a-spin-to-win-wheel-popup-in-under-5-minutes/">head to our blog to know more</a>.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can I save leads collected by spin the wheel popup campaign to MailChimp?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes, Optinly allows you to integrate with MailChimp and other popular ESPs around. You can also integrate with SendFox, Zapier and Pabbly in case you don’t find your ESP on our list.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can I display spin the wheel popups for mobiles and tablets?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes. With Optinly’s device-based triggers, you can choose whether or not to display spin wheel popups for mobiles and tablets. You can also toggle between devices and choose to run campaigns only on mobiles/tablets/desktops. </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>

        <HomePopupClients />
        <GetStarted />

    </Layout>
);

export default SpinTheWheelPopups;